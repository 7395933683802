//$background-color: #f0f0f0;
$background-color: rgb(57, 57, 57);
$background_header_color:  linear-gradient(90deg, rgb(179, 136, 57) 0%,rgb(245, 228, 136) 50%,rgb(179, 136, 57) 100%);
$main_color: #f0f0f0;


@mixin optional-at-root($sel) {
  @at-root #{if(not &, $sel, selector-append(&, $sel))} {
    @content;
  }
}

@mixin placeholder {
  @include optional-at-root('::-webkit-input-placeholder') {
    @content;
  }

  @include optional-at-root(':-moz-placeholder') {
    @content;
  }

  @include optional-at-root('::-moz-placeholder') {
    @content;
  }

  @include optional-at-root(':-ms-input-placeholder') {
    @content;
  }
} 

.inputCardEdit {
 // background-color: grey !important;
 width: 100px !important;
 font-size: 16px !important;
 background: #004fac !important;
  
 
}

.hideKeyboard:focus + .keyboardAction{
  
    background-color: red !important;
  
}
.line{
  height: 4.5vh; 
  background:  rgba(0,0,0,0.1);
  width: 1px;
  margin: 5px;
}

* {
  box-sizing: border-box;
 
}

@font-face {
  font-family: "title2";
  -webkit-font-family:"title2" ;
  -moz-font-family:"title2";
  src: url('../fonts/Vazir-Light.woff')  format('truetype')
}

@font-face {
  font-family: "title3";
  -webkit-font-family:"title3" ;
  -moz-font-family:"title3";
  src: url('../fonts/Vazir-Medium.woff')  format('truetype')
}

  @font-face {
    font-family: "IRANSans";
    -webkit-font-family:"IRANSans" ;
    -moz-font-family:"IRANSans";
    src: url('../fonts/YekanBakh-Bold.woff2')  format('truetype')
  }

  .timeline:before{ 
    border-style: dotted !important;
  }

  body {
  //  margin: 0;
     height: 100%; 
    font-family: 'IRANSans' !important;  
    background-color: #ececec;
    
   // background: arga(14, 169, 96,0.2);
    // background-color:#{$background-color};
       padding: 0px;
       margin: 0px; 
       unicode-bidi: embed; 
  } 
  .noColor{
    color: transparent !important;
  }
  div{
    font-family: 'IRANSans' !important;  
    font-weight: 100;
  }
 
 h1 ,h2 ,li,span{
  font-family: 'IRANSans' !important;   
 }
 p{
  font-family: 'IRANSans' !important; 
  font-weight: normal;
 }
 .codeVocher{
  font-family: initial !important;  
  font-weight: 600;
 }
 .paddingLayout{ 
   padding-top: 7px;
   padding-bottom: 7px;
   width: 18%;
   text-align: center;

 }
 .blurBackground>div:not( .process){
   filter: blur(3px);
   -webkit-filter: blur(3px);
}

.backgroundPostionProcess{
 position: absolute;
 z-index: 5;
}

.unSetFont{
  font-family: initial !important;
    font-size: initial !important;
}

.tableChildRow{
  //  width: 100%;
  align-items: center;
  margin-right: 5px;
  margin-left: 5px;
  padding-right: 10px;
  padding-left: 10px;
    padding-top: 10px;
    font-family: 'title3' ; 
    font-weight: normal;
    font-size: 13px;
    background-color: rgba(221, 221, 221, 0.842);
    margin-bottom: 5px;
    td {
      color:#000;
  text-align: right;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;  
    }
  
     td:first-child{
      color:gray; 
      font-size: 13px;
      min-width: 90px;
    }
  }


 .process{
   border-radius: 20px;
   z-index:1;
   width: 100%;
   height: 100%;
   align-items: center;
   justify-content: center;
   justify-self: center;
   align-content: center;
   //filter: blur(3px);
   //bottom: -10%;
   //display: flex;
   //flex-wrap: wrap;
   position: absolute;
   //-webkit-filter: blur(3px);
   -webkit-border-radius: 20px;
   -moz-border-radius: 20px;
   -ms-border-radius: 20px;
   -o-border-radius: 20px;
  
}
.monyInput{
  position: absolute;
  left:3%;
  color:grey;
  font-size: 12px;
  font-weight: 100 !important;
  top: 25%;
}
 .un-selected{
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  pointer-events: none !important;
 }
 .selected{
  -webkit-touch-callout: auto;
  -webkit-user-select: auto;
  -khtml-user-select: auto;
  -moz-user-select: auto;
  -ms-user-select: auto;
  user-select: auto; 
 }
.card_bank_layout{
  margin-top: 15px;
  background-image: linear-gradient(to bottom right, #182d2f , #244d4f, #244d4f);
 // background-image: linear-gradient(to bottom right, #181818, #1d1d1d , #636363 );
  height: 185px;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  position: relative; 
}
.card_bank_sub_text{
    font-size: 11px;
    margin-top: 5px;
    color: #039BE5;
    animation: shake 2s;
    animation-iteration-count: infinite; 
}
.card_bank_backgroud{
  position: absolute;
  z-index: 0;
  width: 100%;
  height: 100%;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
 // background-color: linear-gradient(to top right, #244d4f, #182d2f, #182d2f, #182d2f);
  }
  .car2{
    top:10%;
    height: 90%;
    
}
.card_bank_chip{
  width: 45px;
  height: 40px;
  position: absolute;
  top: 10%;
  left: 5%;
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(20, 20, 20, 0.6);
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}
.card_bank_logo{
  width: 40px;
  height: 40px;
  position: absolute;
  top: 6%;
  right:5%
}
.card_number{
  color: white;
  font-size: 24px;
  position: absolute;
  width: 100%;
  top: 40%; 
  text-align: center; 
  text-shadow: 1px 1px 1px black; 
  background: transparent;
  border: none;
  outline: none;
  font-family: "IRANSans";
}
.card_bank_title{
  color: #f0f0f0;
  font-size: 12px;
  margin-top: 10px;
  border-bottom: 1px solid rgb(167, 167, 167);
  height: 23px;
}
.name_select_getway{

  position: absolute;
  color: #4495f8 !important;
  font-size: 18px;
  font-weight: bold;
  z-index: 100;
  left:5%

}
.name_select_getway_color{
 
  color: #8E24AA !important; 

}
.name_select_getway_color_green{
 
  color: #00C853 !important; 

}
.card_bank_name{
  color: #FFB300;
  font-size: 22px;
  margin-top: 10px; 
  height: 23px;
  position: absolute;
  bottom: 15%;
  right:10%;
  text-shadow: 1px 1px 1px black;
  z-index: 50;
}
.card_bank_name_bank{
  color: #dbdbdb;
  font-size: 11px;
  margin-top: 10px; 
  position: absolute;
  top: 20%;
  right:5%; 
  min-width: 40px;
  text-align: center;
  z-index: 5;
   
}

.layout_msg_manager{
  direction: rtl;
  text-align: center;
  background-color: #FB8C0010;
  color: #FB8C00;
  padding: 4px;
  border-right: 5px solid #FB8C00;
  font-size: 14px;
  border-radius: 6px;
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  -ms-border-radius: 6px;
  -o-border-radius: 6px;
}

.layout_amount{
  direction: rtl;
  text-align: center;
  background-color: rgb(243, 243, 243);
  color: #43A047;
  padding: 4px;
  border-right: 5px solid #43A047;
  font-size: 24px;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px; 
}
.codeLastStep{
 background-color: #039BE560;
 color: #272727;
  
 
 height: 140px !important;
 width: 140px !important;
 margin-top: 20px;
 text-align: center;
 align-content: center;
 justify-content: center;
 justify-self:  center;
 font-size: 28px;
 border-radius: 50%;
 -webkit-border-radius: 50%;
 -moz-border-radius: 50%;
 -ms-border-radius: 50%;
 -o-border-radius: 50%; 
}
@keyframes example {
  from {background-color: #4CAF5070;}
  to {
    background-color: #039BE570;
    }
}


.background{ 
 //  background: linear-gradient(176deg, #1f5c91 0%,rgba(102, 175, 233, 0.4) 100%); 
 //  background: #{$background_header_color};
  // background: linear-gradient(176deg, rgba(144,164,174,1) 0%, rgba(38,50,56,1) 100%); 
    width: 100%;
    position: absolute;
    z-index: -7;
    left: 0px;
    top: 0px;   
    margin-top: 0px;
    padding-top: 0px;
    height: 320px;
    border-bottom-left-radius: 30%;
    border-bottom-right-radius: 30%;  
    border-color: #353535;
    display: block;
    
    // background-image: url('../images/bg-overlay-home1.png') !important;
    // background-size: contain;
    // background-repeat: no-repeat;
}

.overViewInputCard{
  color: green;
  position: relative;
  width: 100%;
  z-index: 3;
  text-align: center;
  justify-content: center;
  justify-self: center;
  font-size: 14px;
  top: 0%;
  
}
 

.btnClose{
  border-radius: 8px;
  border:2px solid #e53935;
  padding: 5px;
  padding-left: 20px;
  padding-right: 20px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  width: 60%;
  text-align: center;
}

.erorText{
  width: 100%;
  border-right: 6px solid #e53935;
  background-color: #e5393536;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 8px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}
.btnCloseSuccess{
  border-radius: 8px;
  border:2px solid #4CAF50; 
  padding: 5px;
  padding-left: 20px;
  padding-right: 20px;
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
  width: 55%;
  text-align: center;
  color: #4CAF50 ;
  font-size: 14px;

}

#background2{ 
 // background: linear-gradient(176deg, rgba(144,164,174,1) 0%, rgba(38,50,56,0.8) 100%); 
  background: linear-gradient(176deg,#1f5c91 0%, rgba(102, 175, 233, 0.6) 100%); 
   
  width:40%;
  height: 40%;  
  float: center;
  display: flex;
  // width: 50%;
    // height: 50%;
      position: absolute;
      z-index: -5; 
    // margin-top: 0px;
    // padding-top: 0px;
    // border-radius: 50%;
      border-bottom-left-radius: 50%;   
     border-bottom-right-radius: 50%;   
}
.backgroundTitle{
  background-image: url(../images/bg-overlay-home1.png);
  background-color: rgba(57, 57, 57,1);
  background-size: contain;
  background-repeat: repeat;
  background-position: center;
}
html {
  scroll-behavior: smooth;
}
.MuiButton-containedPrimary{
  background-color: red;
}
.entry{
  padding-top: 20vh; 
  padding-bottom: 70vh;
 
}

option{
  padding-left: 20px !important;
  padding-top: 10px!important;
}
.spaceLeft{
max-width: 39% !important;
  margin-right: 10px !important; 
}
.spaceRight{
  max-width: 39% !important;
    margin-left: 10px !important; 
  }
.page {
  position: absolute;
  left: 15px;
  right: 15px;
}

.page-enter {
  opacity: 0;
  transform: scale(1.1);
}

.page-enter-active {
  opacity: 1;
  transform: scale(1);
  transition: opacity 300ms, transform 300ms;
}

.page-exit {
  opacity: 1;
  transform: scale(1);
}

.page-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

.inputCapcha{
  background:transparent !important;
  font-family: 'IRANSans' !important;  
  border: none;
  border-radius: 3px;
  font-size: 14px; 
  text-align: center;
  width: 100%;
  height: 100%;
  padding-top: 10px;
  padding-bottom: 10px; 
  @include placeholder {
    color: gray;
    text-align: center;
    
  }
} 
 
.inputCard{
  background:transparent !important;
  font-family: 'IRANSans' !important;  
  border: none;
  border-radius: 3px;
  font-size: 20px; 
  text-align: center;
  width: 100%;
  height: 100%;
  padding-top: 10px;
  padding-bottom: 10px; 
  @include placeholder {
    color: transparent;
  }
} 

.holder{
 
  @include placeholder {
    color: grey;
  }
}
.irText{
  position: absolute;
  color:gray;
  text-align: center;
  z-index: 1;
  left: 3%; 
  height: 100%; 
  justify-content: center;
  align-items: center;
  align-self: center;
  padding-top: 2%;
  font-size: 16px;
}
.inputCard2{
  font-family: 'IRANSans' !important;  
  border: none; 
  font-size: 14px; 
  text-align: center;
  width: 90%;
  height: 100%;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 40px;
   
   
} 
.inputCard2:focus{
  border: none;
  outline: none;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
.fontDisable{

  font-family: 'Courier New', Courier, monospace !important;
  font-weight: bold !important;
  color: grey;
}
 .layoutInput:focus-within {

   border-color: #66afe9;
  outline: 0; 
  box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102, 175, 233, 0.6);


}

.select_c2c{
  text-align: center;
  background-color: #3e8ae630;
  color:#3e8ae6;
  padding: 10px;
  border-radius: 5px;
  position: relative;
  font-size: 13px;
  border: 1px solid #3e8ae6;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  -ms-border-radius: 5px;
  -o-border-radius: 5px;
  pointer-events: auto;
}
.cpg{
  color:#8E24AA;
  background-color: #8E24AA30;
  border: 1px solid #8E24AA;
}
.select_ico_c2c{
  position: absolute;
  width: 35px;
  height: 35px; 
  right:4%;
  top: 10%;

}
.select_ico_ipg{
  position: absolute;
  width: 45px;
  height: 45px; 
  right:4%;
  top: 10%;
  object-fit: contain;

}

/* HTML: <div class="loader"></div> */

.loadingBtn{
  -webkit-animation:spin 3.5s linear infinite;
  -moz-animation:spin 3.5s linear infinite;
  animation:spin 3.5s linear infinite;
}
 
   
@-moz-keyframes spin { 100% { -moz-transform: rotate(360deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(360deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }
  // border-color: #66afe9;
  // outline: 0; 
  // box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102, 175, 233, 0.6);

 .layoutInput{
   border-radius: 8px;
   border: 1px solid #adadad;
   //margin-right: 10px;
   //margin-left: 10px;
   margin-top: 10px;
   margin-bottom: 30px;
   -webkit-border-radius: 8px;
   -moz-border-radius: 8px;
   -ms-border-radius: 8px;
   -o-border-radius: 8px; 
}

.m-t-15{
  margin-top: 15px;
}

 .layoutInputDiable{
  border-radius: 8px;
  //border: 1px solid #adadad;
  //margin-right: 10px;
  //margin-left: 10px;
  margin-top: 30px;
  margin-bottom: 30px;
  background-color: #f1f1f18c;
  box-shadow: inset 0 1px 1px rgba(0,0,0,.02), 0 0 8px rgba(57, 57, 57, 0.2);
  outline: 0;
  //box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102, 175, 233, 0.6);
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}
.select_title3{
  font-size: 16px;
  background-color: #acacac;
  color:#000000;
  text-align: center;
  padding: 10px;
 // text-shadow: 1px 1px 0px rgb(73, 73, 73);

}

.select_title{
  font-size: 16px;
  background-color: #FEF3D7;
  color:#F57F17;
  text-align: center;
  padding: 10px;
 // text-shadow: 1px 1px 0px rgb(73, 73, 73);

}
.select_title2{
  font-size: 16px;
  background-color: #FEF3D7;
  color:#d32f2f;
  text-align: center;
  padding: 10px;
  border-radius: 8px;
  //text-shadow: 1px 1px 0px rgb(73, 73, 73);
  -webkit-border-radius: 8px;
  -moz-border-radius: 8px;
  -ms-border-radius: 8px;
  -o-border-radius: 8px;
}
 .iconSend{
    color:"red"
 }
 .a{fill:none;}
 .b{fill:#94c1ff;}
 .c{fill:#004fac;}
 .d{fill:#116fff;}
 .fade-in {
  animation: fadeIn ease 2s;
  -webkit-animation: fadeIn ease 2s;
  -moz-animation: fadeIn ease 2s;
  -o-animation: fadeIn ease 2s;
  -ms-animation: fadeIn ease 2s;
  }
  @keyframes fadeIn {
  0% {opacity:1;}
  100% {opacity:0;}
  }
.hiddenScrool{
  overflow-y: hidden !important;
}

  .fade_2 {
  animation: fade2 ease 3s;
  -webkit-animation: fade2 ease 3s;
  -moz-animation: fade2 ease 3s;
  -o-animation: fade2 ease 3s;
  -ms-animation: fade2 ease 3s;
  animation-iteration-count: infinite;

  }
  @keyframes fade2 {
  0% {opacity:1;}
  50% {opacity:0.2;}
   100% {opacity:1;}
  }

  
  .layoutText2{
    display:none
  }
  .animShake {
    /*Starttheshakeanimationandmaketheanimationlastfor0.5seconds*/animation: shake 0.2s;
    /*Whentheanimationisfinished,startagain*/animation-iteration-count: 1;
    -webkit-animation: shake 0.2s;
}
  @keyframes shake {
    10%, 90% {
      transform: translate3d(-1px, 0, 0);
    }
    
    20%, 80% {
      transform: translate3d(2px, 0, 0);
    }
  
    30%, 50%, 70% {
      transform: translate3d(-4px, 0, 0);
    }
  
    // 40%, 60% {
    //   transform: translate3d(4px, 0, 0);
    // }
  }

  @media (min-width: 600px){
    .MuiContainer-maxWidthSm {
      max-width: 470px !important;
  }
  .makeStyles-submit-19 , .makeStyles-submit-10 , .makeStyles-submit-36, .makeStyles-submit-76 , .makeStyles-submit-101{
    margin-right: 100px !important;
    margin-left: 100px !important;
  }
  .inputCard  {
    font-size: 14px; 
    padding-top: 7px;
    padding-bottom: 7px;
    @include placeholder {
      color: grey;
    }
    }


  }




  @media (max-width: 420px) {

    .layoutInput , .layoutInputDiable{
      margin-top: 10px;
    }
    .layoutText2{
      display: block;
      margin-bottom: 0%;
    }
    .makeStyles-editPhoneText-21 > span {
      font-size: 11
      px; 
    }
    .layoutGetWay2{  
      margin-left: 0% !important; 
      margin: 3px;
      max-width: 47% !important;
      flex-basis:  47% !important; 
      margin-left: 0% !important;
    //  box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(57, 57, 57, 0.6);
     }
    // .layoutGetWay2:first-child{
    //  // display: none;
    //  margin: 3px;
    //  max-width: 47% !important;
    //  flex-basis:  47% !important; 
    //  margin-right: 3% !important;
    // // box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(57, 57, 57, 0.6);
    // }
   
    .makeStyles-getway-12{
      box-shadow: 0 4px 4px   rgba(0,0,0,0.1) !important;
      border: none !important;
    

    }
    .makeStyles-small2-23{
       width: 24px !important;
       height: 24px !important;
    }
    .nameGetWay{
      font-size: 12px !important;
      max-width: 90px;
    }
    .PrivateSwitchBase-root-32{
      padding: 0% !important;
    }
    .PrivateSwitchBase-input-33{
      width: 60%;
    
    }
     .inputCard  {
      font-size: 16px; 
      padding-top: 7px;
      padding-bottom: 7px;
      @include placeholder {
        color: grey;
      }
      }


     
      .layoutText{
        display: none;
      }

    .textAmount{
      font-size: 14px;
    }
        
  }
 
  
  @media (max-width: 320px) {

    .makeStyles-marginMenucardNumber-20{
      font-size: 13px !important;
    }
    .makeStyles-btnAddCard-12 {
      font-size: 12px !important;
    }
    .makeStyles-submit-10 , .makeStyles-submit-49{
      margin-left: 50px !important;
      margin-right: 50px !important;
    }
    .layoutInput , .layoutInputDiable{
      margin-top: 10px;
    }
    .layoutText2{
      display: block;
      margin-bottom: 0%;
    }
    .makeStyles-editPhoneText-21 > span {
      font-size: 11
      px; 
    }
    .layoutGetWay2{  
      margin-left: 0% !important; 
      margin: 3px;
      max-width: 47% !important;
      flex-basis:  47% !important; 
      margin-left: 0% !important;
    //  box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(57, 57, 57, 0.6);
     }
    .layoutGetWay2:first-child{
     // display: none;
     margin: 3px;
     max-width: 47% !important;
     flex-basis:  47% !important; 
     margin-right: 3% !important;
    // box-shadow: inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(57, 57, 57, 0.6);
    }
   
    .makeStyles-getway-12{
      box-shadow: 0 4px 4px   rgba(0,0,0,0.1) !important;
      border: none !important;
    

    }
    .makeStyles-small2-23{
       width: 24px !important;
       height: 24px !important;
    }
    .nameGetWay{
      font-size: 11px !important;
      max-width: 90px;
    }
    .PrivateSwitchBase-root-32{
      padding: 0% !important;
    }
    .PrivateSwitchBase-input-33{
      width: 60%;
    
    }
     .inputCard  {
      font-size: 16px; 
      padding-top: 7px;
      padding-bottom: 7px;
      @include placeholder {
        color: grey;
      }
      }


     
      .layoutText{
        display: none;
      }

    .textAmount{
      font-size: 14px;
    }
        
  }
 
