
 ::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track { 
  background: transparent;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background:#dadada;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555; 
}


.layoutDialogStory{
  position: absolute;
  z-index: 100;
  background-color: yellow;
  width: 100%;
  height: 100%;
  top: 0%;
    
}
  
 
.layoutTable:focus{
  
  outline: none;
  border: none;
}
 







 .loader {
  width: 100%;
  height: 100%;
  background: 
    linear-gradient(90deg,rgba(109, 109, 109, 0.067) 33%,#0003 50%,rgba(179, 179, 179, 0.067) 66%)
    #f2f2f2;
  background-size:300% 100%;
  animation: l1 1s infinite linear;
}
@keyframes l1 {
  0% {background-position: right}
}

 .selectImageOut{ 
      -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    opacity: 1 !important; 
}
.selectImage{ 
      -webkit-transform: scale(0.9);
    transform: scale(0.9);
    -webkit-transition: all .2s ease-in-out;
    transition: all .2s ease-in-out;
    opacity: .5 !important; 
}
@keyframes scale {
  to {
    transform: scale(0.95);
  }
}
 .hightCaptcha{
    min-height: 390px;
    min-width: 380px
  }
  .hightCaptchaImg{
    height: 115px;
  }


  @media screen and (max-height: 650px){
 
    .hightCaptcha{
      min-height: 370px; 
      
    }
  .selectImageOut{
    height: 110px !important;
  }
   .hightCaptchaImg{
    height: 110px !important;
  }

  .titleCaptcha{
    margin-top: 0px !important;
    margin-bottom: 8px !important;
  }
   .titleCaptcha>div{ 
    
    font-size: 20px !important;
    margin-top: 0px !important;
  }
  .btnCaptcha{
    margin-top: 0px !important; 
    margin-bottom: 5px !important;
  }

 
 
}
@media screen and (max-height: 513px){
 
  .hightCaptcha{
      min-height: 330px !important;  
  }
   
   .selectImageOut{
    height: 100px !important;
  }
   .hightCaptchaImg{
    height: 100px !important;
  }
   
   .titleCaptcha>div{ 
    
    font-size: 17px !important; 
  } 
}
@media screen and (min-width: 600px){


  .hightCaptcha{
    min-height: 460px;
    min-width: 445px
  }
   .hightCaptchaImg{
   height: 138px;
  }
  .MuiDialog-paperWidthSm{
    max-height: 500px  !important;
    min-width: 600px;
  }

  .tableChildRow{
    width: 100%;
    font-size: 18px;  
  }
  .tableChildRow > td {
    font-size: 18px;  
  }

  .tableChildRow >td:first-child{ 
    font-size: 18px; 
  }
 
  input:focus-visible{
    outline: none;
  }
  .cardNumber{
    font-size: 16px !important;
  }
   .lastChildCardNumber:last-child{
    display: none;
   }
   .dash_{
    color:#cecece
   }

  .description{ 
    text-align: right;  
  }
  .description::before{
    content:none !important;
  }
  .status0 ,.status1 ,.status2 , .status3{
    font-size: 11px;
  }
  .descrip{
    text-align: right;
    font-size: 12px;
    color:grey; 
  }
  
  .makeStyles-btnDelete-54{
    height: 27px !important;
    padding-left: 5px !important;
    padding-right: 5px !important;
    max-width: 70px !important;
  }
  .makeStyles-btnDelete-54 > span {
   font-size: 10px !important;
  }
}
.description{ 
  text-align: right;  
}
.description::before{
  content:none !important;
} 
 @media screen and (max-width: 600px) {

  .hightCaptcha{ 
    min-width: 350px
  }
  .cardNumber{
    font-size: 16px !important;
  }
  
   

  .description{ 
    text-align: right;  
  }
  .description::before{
    content:none !important;
  }
}  
.status3{
  border-radius: 40px;
  -webkit-border-radius: 40px;
  -moz-border-radius: 40px;
  -ms-border-radius: 40px;
  -o-border-radius: 40px;
  color:#7CB342;
  background-color: #DCEDC8;
  padding-right: 5px;
  padding-left: 5px;
  padding-top: 3px;
  padding-bottom: 3px;
}
.status1{
  border-radius: 40px;
  -webkit-border-radius: 40px;
  -moz-border-radius: 40px;
  -ms-border-radius: 40px;
  -o-border-radius: 40px;
  color:#fff;
  background-color: #4CAF50;
  padding-right: 5px;
  padding-left: 5px;
  padding-top: 3px;
  padding-bottom: 3px;
}
.status0{
  border-radius: 40px;
  -webkit-border-radius: 40px;
  -moz-border-radius: 40px;
  -ms-border-radius: 40px;
  -o-border-radius: 40px;
  color:rgb(48, 48, 48)70;
  background-color: #c2c2c270;
  padding-right: 5px;
  padding-left: 5px;
  padding-top: 3px;
  padding-bottom: 3px;
}
.status2{
  border-radius: 40px;
  -webkit-border-radius: 40px;
  -moz-border-radius: 40px;
  -ms-border-radius: 40px;
  -o-border-radius: 40px;
  color:#d50000;
  background-color: #ef9a9a90;
  padding-right: 15px;
  padding-left: 15px;
  padding-top: 3px;
  padding-bottom: 3px; 
 
}
  
.layoutProcess{
  position: static !important;
  min-width:  250px !important;
  min-height: 250px  !important; 
  padding-top: 20%;
  padding-left: 25%;
  margin-left: 20px;
}
   .lds-spinner {
    color: official;
    display: inline-block;
    position: relative;
    width: 40px;
    height: 40px; 
  }
  .lds-spinner div {
    transform-origin: 50px 40px;
    animation: lds-spinner 1.2s linear infinite;
  }
  .lds-spinner div:after {
    content: " ";
    display: block;
    position: absolute;
    top: 21px;
    left: 47px;
    width: 3px;
    height: 8px;
    border-radius: 30%;
    background: #9C27B0;
    -webkit-border-radius: 30%;
    -moz-border-radius: 30%;
    -ms-border-radius: 30%;
    -o-border-radius: 30%;
}
  .lds-spinner div:nth-child(1) {
    transform: rotate(0deg);
    animation-delay: -1.1s;
  }
  .lds-spinner div:nth-child(2) {
    transform: rotate(30deg);
    animation-delay: -1s;
  }
  .lds-spinner div:nth-child(3) {
    transform: rotate(60deg);
    animation-delay: -0.9s;
  }
  .lds-spinner div:nth-child(4) {
    transform: rotate(90deg);
    animation-delay: -0.8s;
  }
  .lds-spinner div:nth-child(5) {
    transform: rotate(120deg);
    animation-delay: -0.7s;
  }
  .lds-spinner div:nth-child(6) {
    transform: rotate(150deg);
    animation-delay: -0.6s;
  }
  .lds-spinner div:nth-child(7) {
    transform: rotate(180deg);
    animation-delay: -0.5s;
  }
  .lds-spinner div:nth-child(8) {
    transform: rotate(210deg);
    animation-delay: -0.4s;
  }
  .lds-spinner div:nth-child(9) {
    transform: rotate(240deg);
    animation-delay: -0.3s;
  }
  .lds-spinner div:nth-child(10) {
    transform: rotate(270deg);
    animation-delay: -0.2s;
  }
  .lds-spinner div:nth-child(11) {
    transform: rotate(300deg);
    animation-delay: -0.1s;
  }
  .lds-spinner div:nth-child(12) {
    transform: rotate(330deg);
    animation-delay: 0s;
  }
  @keyframes lds-spinner {
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
  }



  .lds-roller {
    display: inline-block;
    position: relative;
    width: 90px;
    height: 90px; 
    top: 35%;
    left: 40%;
  }
  .lds-roller div {
    animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 40px 40px;
  }
  .lds-roller div:after {
    content: " ";
    display: block;
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: rgb(13, 148, 226);
    margin: -4px 0 0 -4px;
  }
  .lds-roller div:nth-child(1) {
    animation-delay: -0.036s; 
  }
  .lds-roller div:nth-child(1):after {
    top: 63px;
    left: 63px;
  }
  .lds-roller div:nth-child(2) {
    animation-delay: -0.072s;
  }
  .lds-roller div:nth-child(2):after {
    top: 68px;
    left: 56px;
  }
  .lds-roller div:nth-child(3) {
    animation-delay: -0.108s;
  }
  .lds-roller div:nth-child(3):after {
    top: 71px;
    left: 48px;
  }
  .lds-roller div:nth-child(4) {
    animation-delay: -0.144s;
  }
  .lds-roller div:nth-child(4):after {
    top: 72px;
    left: 40px;
  }
  .lds-roller div:nth-child(5) {
    animation-delay: -0.18s;
  }
  .lds-roller div:nth-child(5):after {
    top: 71px;
    left: 32px;
  }
  .lds-roller div:nth-child(6) {
    animation-delay: -0.216s;
  }
  .lds-roller div:nth-child(6):after {
    top: 68px;
    left: 24px;
  }
  .lds-roller div:nth-child(7) {
    animation-delay: -0.252s;
  }
  .lds-roller div:nth-child(7):after {
    top: 63px;
    left: 17px;
  }
  .lds-roller div:nth-child(8) {
    animation-delay: -0.288s;
  }
  .lds-roller div:nth-child(8):after {
    top: 56px;
    left: 12px;
  }
  @keyframes lds-roller {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  